
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

        <v-card class="card">
            <div v-if="init_loading">
                <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-card-title class="heading justify-center">QRcode Report 3</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <label>Accession Number</label>

                            <v-text-field class="text" v-model="accno" persistent-hint dense outlined
                                v-on="on"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <label class="add-text">Series</label>
                            <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data"
                                item-text="name" item-value="id" outlined dense class="text" :rules="[rules.required]"
                                required></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                            <v-checkbox v-model="isrange" label="Range"></v-checkbox>

                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <br /><br />
                            <v-text-field class="text" persistent-hint dense placeholder="From" type="number" outlined
                                :disabled="!isrange" :readonly="!isrange" v-model="from"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <br /><br />
                            <v-text-field class="text" persistent-hint dense placeholder="To" type="number" outlined
                                :disabled="!isrange" :readonly="!isrange" v-model="to"></v-text-field>

                        </v-col>
                    </v-row>
                </v-card-text>
                <div>
                    <center>
                        <v-btn color="success" class="mb-4" @click="dailyData1">Fetch Data</v-btn>
                    </center>
                </div>


                <div v-if="loader">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-data-table :headers="headers" :search="search" :items="tabledata" class="elevation-5 ma-2">
                        <template v-slot:item.barcode="{ item }">

                            <vue-qrcode :value="item.barcode" />
                            <br>
                            <b style="margin-left: 45px">{{ item.barcode }} </b>
                            <!-- <barcode :value="item.barcode"></barcode> -->
                        </template>
                        <template v-slot:top>
                            <v-toolbar flat color="white" align="center" justify="center">
                                <v-toolbar-title>Barcode-Report</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="search" label="Search" single-line
                                    hide-details></v-text-field>

                                <v-btn v-if="Array.isArray(tabledata) && tabledata.length" @click="printElem('exceltable2')"
                                    color="success" dark class="mb-2 ma-2">
                                    Print
                                </v-btn>
                                <!--  <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="fields"
                worksheet="My Worksheet"
                name="New Arrival Report.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>-->
                            </v-toolbar>
                        </template>
                    </v-data-table>
                </div>
            </div>


            <div id="exceltable2" class="A-barcode-size" style="display: none">
                <!-- <div class="row"> -->
                <div v-for="(i, index) in tabledata" :key="index">
                    <!-- <div class="column"> -->
                    <section class="sheet padding-1mm">
                    <div class="qrcode-wrapper">
                        <div  class="qrcode"> 
                          <vue-qrcode margin="0" :value="i.barcode" />
                     </div>
                     <div style="left: 1%" class="qrcode-label">
                        <b style="text-align: center;">{{ i.barcode }} </b>
                    </div>
                </div>

                        <!-- <barcode :value="i.barcode"></barcode> -->
                    </section>
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
// import VueBarcode from "vue-barcode";
import VueQrcode from 'vue-qrcode'
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        isrange: true,
        from: 1,
        to: 10,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Reports',
                disabled: false,
                href: '/reports',
            },
            {
                text: 'Qrcode Report 3',
                disabled: true,
                href: '',
            },
        ],
        fields: {
            Series: "series",
            "Accession No": "author",
            "QRcode No": "barcode_no",
            //"Barcode": "barcode",
            Barcode: {
                field: "barcode",
                callback: (item) => {
                    return item == "" ? "" : ` <vue-qrcode :value="item" />`;
                },
            },
        },

        init_loading: false,
        loader: false,
        selected: [],
        accno: "",
        errorMsg: {
            roleLinkId: false,
        },
        rules: {
            required: (value) => !!value || "Required.",
        },
        tabledata: [],
        snackbar: false,
        snackbar_msg: "",
        color: "",
        search: "",

        series_data: [],

        series: "",

        headers: [
            {
                text: "Sr no",
                align: "left",
                sortable: true,
                value: "srno",
            },
            {
                text: "Series",
                align: "left",
                sortable: true,
                value: "series",
            },
            {
                text: "Accession No",
                align: "left",
                sortable: true,
                value: "acc_no",
            },
            {
                text: "QRcode No",
                align: "left",
                sortable: true,
                value: "barcode_no",
            },
            {
                text: "QRcode",
                align: "left",
                sortable: true,
                value: "barcode",
            },
        ],
    }),
    components: {
        VueQrcode,

    },
    computed: {
        filteredDesserts() {
            return this.dailyData.filter((d) => {
                return Object.keys(this.filters).every((f) => {
                    return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                });
            });
        },
        dateRangeText() {
            return this.dates.join(" ~ ");
        },
    },

    mounted() {
        this.onLoad();
    },
    methods: {
        printElem(divId) {
            var content = document.getElementById(divId).innerHTML;
            var mywindow = document.body.innerHTML;

            document.body.innerHTML = content;
            window.print();
            document.body.innerHTML = mywindow;
            location.reload();
            return true;
        },
        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "qrcode_report", //do not include extension
                fileext: ".xls", // file extension
            });
        },
        dailyData1() {
            if (this.isrange && this.from > this.to)
                this.showSnackbar("red", "Please enter Valid range");
            if (this.isrange && (this.from == '' || this.to == ''))
                this.showSnackbar("red", "Please enter Valid range");
            else {
                this.loader = true;

                const data = {
                    accno: this.accno,
                    series_id: this.series,
                    isrange: this.isrange,
                    from: this.from,
                    to: this.to
                };
                axios
                    .post("/Librarian/getserieswiseaccno", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loader = false;
                            this.tabledata = res.data.data;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        this.loader = false;
                        window.console.log(error);
                    })
                    .finally(() => { });
            }
        },
        onLoad() {
            this.init_loading = true;
            axios
                .post("/Librarian/getseriesData")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.init_loading = false;
                        this.series_data = res.data.series_data;
                        this.series_data.push({ name: "ALL", id: "ALL" });
                        this.series = "ALL";
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                    // var overlay = false;
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.cus-card {
    background: #d3d3d33d;
    margin: 1rem;
    padding: 1rem;
    border: 2px solid gray;
}

.c-label {
    font-weight: bold;
}

.add-section {
    display: block;
    margin: 1rem;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
    white-space: nowrap !important;
}

.d-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem;
}

.column {
    float: left;
    width: 50%;
    padding: 10px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

@page {
    size: 51mm 25mm;
}
.qrcode-wrapper {
  position: relative; 
}
.qrcode {

}
.qrcode-label {
  position: absolute; 
 
}
.sheet {
    margin: 1px auto;
    page-break-after: always;
    margin-top: 5mm;
}




/*
.sheet.padding-1mm { padding: 1mm;
 margin-top:10mm }*/

@media print {


    #notprint {
        display: none;
    }

}</style>
